<template>
  <div id="body">
    <h6 style="color: blue !important; position: absolute; top: 10px; right: 18px;">
      <router-link to="/home">Home</router-link> &nbsp;&nbsp;&#183;&nbsp;&nbsp; 
      <router-link to="/auth/login">Login</router-link> &nbsp;&nbsp;&#183;&nbsp;&nbsp; 
      <router-link to="/auth/register">Register</router-link>
    </h6>

    <div class="container form">
		  <div class="card0 d-flex flex-lg-row flex-column-reverse">

        <!-- <h3><span>Frequently </span> Asked Questions</h3>
        <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p> -->

        <VueFaqAccordion 
          :items="faqs"
        />
        
      </div>
    </div>
  </div>
</template>

<script>
import VueFaqAccordion from 'vue-faq-accordion'

export default {
  components: {
    VueFaqAccordion
  },
  data () {
    return {
      faqs: [
        {
          title: 'What is a Visitor Management System (VMS)?',
          value: 'Visitor Management allows you to track the people who come and go from your worksite. A Visitor Management System (VMS) replaces the sign-in paper and allows you to go digital. VMS creates an easy and efficient way for you to visits, to sign-in and out from your worksite.',
        },
        {
          title: 'How I Use VMS?',
          value: 'Please read manual book <a target="_blank" href="https://dcvisit.lintasarta.net/UserManual.pdf">here.</a>',
        },
        {
          title: 'What is a QR code?',
          value: 'A QR code is an encoded piece of data that looks and works like a barcode. It is a machine-scannable image, that contains information. Using a QR code is an easy way to instantly access stored information. When you scan the QR code, your device provides you with the information it contains.',
        },
        {
          title: 'What information will be covered in follow-up email?',
          value: 'The follow up email will be covered with the sign-in time, the visitor name, the location, the host’s name, and the email address. You can customize the text in subject and content message.',
        },
        {
          title: 'What if  lost my Password?',
          value: 'Click on forgot password menu, then fill your register email, an then check your email, we send the link for change password.',
        },
        {
          title: 'When i have to use signature pad?',
          value: 'The signature pad use when you ready to sign NDA form and/or BAST (shipping) form.',
        },
        {
          title: 'How i can contact for help?',
          value: 'You can contact us at<br>Email : <strong>support@lintasarta.co.id</strong><br>Hotline : <strong>14052</strong>',
        }
      ]
    }
  }
}
</script>

<style lang="scss">
  // Import custom styles for this application
  @import '@/assets/css/style';
</style>

<style scoped lang="css">
.card0 {
  font-weight: bold;
  background: #fff;
}
</style>